exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-branding-index-tsx": () => import("./../../../src/pages/branding/index.tsx" /* webpackChunkName: "component---src-pages-branding-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-e-ticaret-index-tsx": () => import("./../../../src/pages/e-ticaret/index.tsx" /* webpackChunkName: "component---src-pages-e-ticaret-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-social-media-index-tsx": () => import("./../../../src/pages/social-media/index.tsx" /* webpackChunkName: "component---src-pages-social-media-index-tsx" */),
  "component---src-pages-web-design-index-tsx": () => import("./../../../src/pages/web-design/index.tsx" /* webpackChunkName: "component---src-pages-web-design-index-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/Templates/blog.post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-project-post-tsx": () => import("./../../../src/Templates/project.post.tsx" /* webpackChunkName: "component---src-templates-project-post-tsx" */)
}

